import React, { Component } from 'react';
import Button from '../../reusableComponents/button';
import Input from '../../reusableComponents/input';
import LoginLine from '../../reusableComponents/line';
import Header from '../../reusableComponents/header';
import SubHeader from '../../reusableComponents/subHeader';
import queryString from 'query-string';
import BackgroundPaper from '../../reusableComponents/backgroundPaper';
import { Grid } from '@material-ui/core';
import EmailVerificationInput from './EmailVerificationInput';
import FamilyLandingBackground from './FamilyLanding/FamilyLandingBackground';
import { client, consoleLogInDev } from 'shared';
import { ApiBaseUrl } from 'environmentVariables';
import { Text } from 'library';

export default class VerificationCode extends Component {
    constructor(props) {
        super(props);
        this.legacy = window.location.href.includes('create');
        this.state = {
            code: '',
            isCode: true,
            token: '',
            page: 0,
            email: '',
            emailValid: false,
            isPhoneNumber: false,
            phoneNumber: queryString.parse(this.props.location.search).num || '',
            loading: false,
            enabledVerify: false,
            correctCode: true,
            action: 'Reset',
            codeRequestError: false,
        };
        this.confirm = this.confirm.bind(this);
    }

    componentDidMount() {
        const { phoneNumber } = this.state;
        if (phoneNumber !== '' && phoneNumber != null) {
            this.onPhoneNumberTextChange();
            this.deleteToken = false;
        } else if (this.props.legacy) {
            this.deleteToken = false;
        } else {
            this.deleteToken = true;
        }
    }

    onPhoneNumberTextChange = (e = '') => {
        const text = e !== '' ? e.target.value : this.state.phoneNumber;
        var cleaned = ('' + text).replace(/\D/g, '');
        var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            var number = ['(', match[2], ') ', match[3], '-', match[4]].join('');
            var parsedPhoneNumber = number.slice(1, 4) + number.slice(6, 9) + number.slice(10, 14);
            if (
                parsedPhoneNumber[0] === '0' ||
                parsedPhoneNumber[0] === '1' ||
                parsedPhoneNumber[3] === '0' ||
                parsedPhoneNumber[3] === '1'
            ) {
                this.setState({ isPhoneNumber: false, phoneNumber: number });
            } else {
                this.setState({ isPhoneNumber: true, phoneNumber: number });
            }
            return;
        }
        this.setState({
            phoneNumber: text,
        });
    };

    onCodeTextChange = (e) => {
        const text = e.target.value.substring(0, 4);
        this.setState({
            code: text,
        });
        var cleaned = ('' + text).replace(/\D/g, '');
        var match = cleaned.match(/^\d{4}$/);
        if (match) {
            this.setState({ isCode: true, code: text });
            this.setState({ enabledVerify: true });
            return;
        } else {
            this.setState({ enabledVerify: false });
        }
    };

    async confirm() {
        const { phoneNumber, email, emailValid } = this.state;
        if (this.state.phoneNumber) {
            var parsedPhoneNumber = phoneNumber.slice(1, 4) + phoneNumber.slice(6, 9) + phoneNumber.slice(10, 14);

            try {
                await client('send-verification-text/?receiver=' + parsedPhoneNumber, { method: 'POST' });
                this.setState({ codeRequestError: false });
                this.setState({ page: this.state.page + 1 });
            } catch (error) {
                this.setState({ codeRequestError: true });
                consoleLogInDev(error);
            }
        } else if (email && emailValid) {
            client(`api/users/send-verification-email/?email=${email}`)
                .then(() => {
                    this.setState({ page: this.state.page + 1 });
                })
                .catch(consoleLogInDev);
        }
    }

    async verifyCode() {
        this.setState({ loading: true });
        const { phoneNumber, code } = this.state;
        var parsedPhoneNumber = phoneNumber.slice(1, 4) + phoneNumber.slice(6, 9) + phoneNumber.slice(10, 14);
        if (code != '' && code != null) {
            let response = '';
            if (this.deleteToken) {
                try {
                    response = await fetch(
                        ApiBaseUrl +
                            '/delete-token-and-check-verification/?receiver=' +
                            parsedPhoneNumber +
                            '&code=' +
                            code,
                        {
                            method: 'POST',
                        },
                    );
                    this.setState({ loading: false });
                } catch (error) {
                    consoleLogInDev(error);
                }
            } else {
                try {
                    response = await fetch(
                        ApiBaseUrl + '/check-verification-code/?receiver=' + parsedPhoneNumber + '&code=' + code,
                        {
                            method: 'POST',
                        },
                    );
                    this.setState({ loading: false });
                } catch (error) {
                    consoleLogInDev(error);
                }
            }
            let responseJson = await response.json();
            this.setState({ token: responseJson.token });
            localStorage.setItem('token', responseJson.token);
            if (responseJson.status === 'approved') {
                // eslint-disable-next-line no-comments/disallowComments
                // use window.location to force page refresh. Something isn't right with the Private wrapper after setting the token
                window.location = '/signin/change/password';
            } else {
                this.setState({ loading: false, correctCode: false, code: '' });
            }
        } else {
            this.setState({ loading: false, isCode: false });
        }
    }

    backPage() {
        const { page } = this.state;
        this.setState({ page: page - 1 });
    }

    forwardPage() {
        const { page } = this.state;
        this.setState({ page: page + 1 });
    }

    onChangeText = async (name, e) => {
        this.setState({ [name]: e.target.value });
    };

    render() {
        const { code, isCode, correctCode, email, phoneNumber, isPhoneNumber, emailValid, enabledVerify, action } =
            this.state;
        switch (this.state.page) {
            case 0:
                return (
                    <div>
                        <FamilyLandingBackground>
                            <Grid container style={{ height: '75vh' }} justify="center" alignContent="flex-start">
                                <Grid item md={6} sm={9} xs={10}>
                                    <BackgroundPaper style={{ marginBottom: 15 }}>
                                        <Grid container direction="column">
                                            <Header variant="h5" gutterBottom>
                                                {action} Password
                                            </Header>
                                            <SubHeader variant="h6">
                                                Start the password reset proccess by entering the email address or phone
                                                number you signed up with.
                                            </SubHeader>
                                            <Grid>
                                                <LoginLine>
                                                    <Input
                                                        error={!!phoneNumber && !isPhoneNumber}
                                                        helperText={
                                                            !!phoneNumber && !isPhoneNumber
                                                                ? 'Please enter a valid phone number'
                                                                : ''
                                                        }
                                                        fullWidth
                                                        id="phoneNumber"
                                                        label="Phone Number"
                                                        variant="outlined"
                                                        value={phoneNumber}
                                                        onChange={this.onPhoneNumberTextChange}
                                                    />
                                                </LoginLine>
                                            </Grid>
                                            <Text
                                                textStyle={{
                                                    width: '100%',
                                                    marginBottom: 20,
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                or
                                            </Text>
                                            <EmailVerificationInput
                                                email={email}
                                                setEmail={(email) => this.setState({ email })}
                                                setEmailValid={(valid) => this.setState({ emailValid: valid })}
                                            />
                                            {this.state.codeRequestError ? (
                                                <div style={{ color: 'red' }}>
                                                    There was an error sending the code. Please try again in a few
                                                    minutes.
                                                </div>
                                            ) : (
                                                ''
                                            )}
                                            <Grid>
                                                <LoginLine>
                                                    <Button
                                                        fullWidth
                                                        disabled={
                                                            (!emailValid && !isPhoneNumber) ||
                                                            (email && phoneNumber) ||
                                                            (!email && !phoneNumber)
                                                        }
                                                        type="submit"
                                                        onClick={this.confirm}
                                                        variant="contained"
                                                    >
                                                        {phoneNumber ? 'Send Code' : 'Send Email'}
                                                    </Button>
                                                    <Text textStyle={{ color: 'red' }}>
                                                        {email && phoneNumber
                                                            ? 'Enter either a phone number or email address but not both'
                                                            : ''}
                                                    </Text>
                                                </LoginLine>
                                            </Grid>
                                        </Grid>
                                    </BackgroundPaper>
                                </Grid>
                            </Grid>
                        </FamilyLandingBackground>
                    </div>
                );
            case 1:
                return (
                    <div className="">
                        <FamilyLandingBackground>
                            <Grid container style={{ height: '75vh' }} justify="center" alignContent="flex-start">
                                <Grid item lg={8} md={8} sm={9} xs={10}>
                                    <BackgroundPaper style={{ maxHeight: 275, marginBottom: 15 }}>
                                        <Grid item container direction="column" justify="center">
                                            <Header variant="h5" gutterBottom>
                                                {action} Password
                                            </Header>
                                            <SubHeader variant="h6" gutterBottom>
                                                {phoneNumber
                                                    ? `Enter the verification code sent to ${phoneNumber}`
                                                    : [
                                                          `An email will be sent to ${email} if it belongs to an account in our system.`,
                                                          'Open it and follow the instructions to reset your password.',
                                                      ].join(' ')}
                                            </SubHeader>
                                            {phoneNumber && (
                                                <>
                                                    <Grid>
                                                        <LoginLine>
                                                            <Input
                                                                required
                                                                error={!isCode}
                                                                helperText={
                                                                    !isCode
                                                                        ? 'Please enter the four-digit verification code.'
                                                                        : ''
                                                                }
                                                                fullWidth
                                                                id="code"
                                                                label="Verification Code"
                                                                variant="outlined"
                                                                value={code}
                                                                onChange={this.onCodeTextChange}
                                                            />
                                                        </LoginLine>
                                                        {!correctCode ? (
                                                            <div style={{ color: 'red' }}>Code Not Valid</div>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </Grid>
                                                    <Grid>
                                                        <LoginLine>
                                                            <Button
                                                                fullWidth
                                                                disabled={!enabledVerify}
                                                                type="submit"
                                                                onClick={() => this.verifyCode()}
                                                                variant="contained"
                                                            >
                                                                Verify Code
                                                            </Button>
                                                        </LoginLine>
                                                    </Grid>
                                                </>
                                            )}
                                        </Grid>
                                    </BackgroundPaper>
                                </Grid>
                            </Grid>
                        </FamilyLandingBackground>
                    </div>
                );
            default:
                return null;
        }
    }
}
