import React, { useEffect } from 'react';
import { FormFieldProps } from '../../FormTypes';
import { Grid } from '@material-ui/core';
import moment from 'moment';
import { useWidth } from 'reusableComponents/useWidth';
import JobDatePicker from './JobDatePicker';
import { Text } from 'library';
import { isBefore, isSameDay } from 'date-fns';
import { DayOfWeekSelections } from 'parent-portal/forms/JobRequestTypes';
import { daysOfTheWeek as indexToWeekday } from 'shared/Dates';

interface JobDateRangePickerProps {
    startDate: FormFieldProps<moment.Moment | null>;
    endDate: FormFieldProps<moment.Moment | null>;
    daysOfTheWeek?: FormFieldProps<DayOfWeekSelections>;
}

export default function BusinessJobDateRangePicker({ startDate, endDate, daysOfTheWeek }: JobDateRangePickerProps) {
    const width = useWidth();

    useEffect(() => {
        const valid = startDate.value !== null && endDate.value !== null && startDate.value <= endDate.value;
        startDate.setValid(valid);
        if (isBefore(endDate.value?.toDate() ?? new Date(), startDate.value?.toDate() ?? new Date())) {
            endDate.setValue(startDate.value);
        }

        if (isSameDay(startDate.value?.toDate() ?? new Date(), endDate.value?.toDate() ?? new Date())) {
            if (!daysOfTheWeek || !startDate.value) return;
            const dayName = indexToWeekday[startDate.value.toDate().getDay()];
            const newDaysOfTheWeek = { ...daysOfTheWeek.value };
            Object.keys(newDaysOfTheWeek).forEach((key) => {
                newDaysOfTheWeek[key as keyof DayOfWeekSelections].selected = key === dayName;
            });
            if (JSON.stringify(newDaysOfTheWeek) !== JSON.stringify(daysOfTheWeek.value)) {
                daysOfTheWeek.setValue(newDaysOfTheWeek);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startDate, endDate]);

    return (
        <Grid container>
            <Grid item style={{ marginRight: width === 'xs' ? 0 : 30, width: width === 'xs' ? '100%' : undefined }}>
                <Text variant="caption">Start Date</Text>
                <JobDatePicker
                    jobDate={startDate}
                    additionalErrorState={
                        !!startDate.value &&
                        !!endDate.value &&
                        (startDate.value as moment.Moment) < (endDate.value as moment.Moment)
                    }
                    maxDate={moment().add(3, 'months')}
                    id="start-date"
                />
            </Grid>
            <Grid item style={{ width: width === 'xs' ? '100%' : undefined }}>
                <Text variant="caption">End Date</Text>
                <JobDatePicker
                    jobDate={endDate}
                    minDate={startDate.value || moment()}
                    maxDate={moment(startDate.value).add(3, 'months')}
                    id="end-date"
                />
            </Grid>
        </Grid>
    );
}
