import React from 'react';
import Notes from 'internal/shared/tables/Notes';
import StaffInfo from '../../../StaffInfo';
import { Refresh, ModelTraining } from '@mui/icons-material';
import ProviderExternalMessaging from 'internal/providers/ProviderExternalMessaging';
import { Grid } from '@material-ui/core';
import { ProviderOutlawModal } from 'internal/shared/UserActions';
import OutreachHeaderMessages from '../../../OutreachHeaderMessages';
import BugReportsDialog from 'internal/shared/BugReportsDialog';
import { Text, IconButton, Colors } from 'library';
import { AdminProvider } from 'models';
import { refreshInfoCollect } from 'api/AdminInfoCollectApi';
import { consoleLogInDev } from 'shared';
import StreamRefreshInfoCollect from './StreamRefreshInfoCollect';
import ShowWorkerInMap from 'internal/substitutes/ShowWorkerInMap';
import { format } from 'date-fns';
import DependabilityMetric from './DependabilityMetric';
import { styled } from '@mui/material';
import AdminButton from 'internal/shared/AdminButton';
import { addChatwootContact, toggleContactEnabled } from 'internal/support/chatwootApi';

export function WorkerProfileHeader({
    provider,
    refreshProvider,
}: {
    provider: AdminProvider;
    refreshProvider: () => void;
}) {
    const name = `${provider?.user.first_name} ${provider?.user.last_name}`;
    const compliance_state = provider?.user.compliance_state;
    function refreshWorkerInfoCollect() {
        const infoCollectRefresh = refreshInfoCollect(compliance_state, provider);
        if (!infoCollectRefresh) {
            alert('No compatible info collect found');
        } else {
            infoCollectRefresh()
                .then((resp) => {
                    alert('Done refreshing Provider Info Collect! Status: ' + resp?.status);
                    refreshProvider();
                })
                .catch(consoleLogInDev);
        }
    }

    function toggleChatwoot() {
        if (!provider.chatwoot_contact_id) {
            addChatwootContact(provider.user.id).then(refreshProvider);
        } else {
            toggleContactEnabled(provider.chatwoot_contact_id, false).then(refreshProvider);
        }
    }

    const firstVettedDateText = provider.first_vetted_date
        ? `First vetted on: ${format(new Date(provider.first_vetted_date), 'MM/dd/yyyy')}`
        : '';
    const firstVettedCaveat =
        provider.first_vetted_date && new Date(provider.first_vetted_date) < new Date('2024-11-06')
            ? ` (this is an estimate)`
            : '';
    const firstVettedDisplay = `${firstVettedDateText}${firstVettedCaveat}`;

    return (
        <Grid container style={{ margin: 10 }}>
            <Grid container style={styles.topRowWrap}>
                <HeaderDiv>
                    <Grid item direction="column" style={styles.providerNameWrap}>
                        <Text bold>
                            {name} ({provider.user.id}){' '}
                            {!provider.user.show_spotlight && <Text variant="caption">Profile Share: 🚫 No</Text>}
                        </Text>
                        <Text variant="caption">{provider.user.email}</Text>
                        {firstVettedDisplay && <Text variant="caption">{firstVettedDisplay}</Text>}
                        <OutreachHeaderMessages provider={provider} />
                    </Grid>
                    <DependabilityMetric provider={provider} />
                </HeaderDiv>
                <Grid>
                    <Grid item direction="row" style={styles.iconGroupWrap}>
                        <Notes
                            tooltip="Add notes to this provider"
                            name={name}
                            type="admin-providers"
                            objectId={provider.id}
                            refresh={refreshProvider}
                            style={styles.removeMargin}
                        />
                        <IconButton
                            tooltip="Refresh worker details"
                            icon={Refresh}
                            onClick={refreshProvider}
                            style={styles.adjustIconMargin}
                        />
                        <ShowWorkerInMap region={provider.region_info?.area} userId={provider.user.id} />
                        <StaffInfo userId={provider.user.id} />
                        <ProviderExternalMessaging user={provider.user} />
                        <ProviderOutlawModal user={provider.user} />
                        {compliance_state === 'Florida' ? (
                            <StreamRefreshInfoCollect
                                streamEndpoint={`api/fl-training-info-collect/${provider.user.id}/refresh/`}
                                refreshActions={['Clearinghouse', 'DCF Trainings', 'Everything']}
                            />
                        ) : (
                            <IconButton
                                tooltip="Refresh worker info collect"
                                icon={ModelTraining}
                                onClick={refreshWorkerInfoCollect}
                                style={styles.adjustIconMargin}
                            />
                        )}
                        <BugReportsDialog user={provider.user} />
                    </Grid>
                    <AdminButton
                        btnStyles={{ marginTop: 10, padding: 8 }}
                        text={provider.chatwoot_contact_id ? 'Disable Chatwoot' : 'Enable Chatwoot'}
                        color={provider.chatwoot_contact_id ? Colors.lightRed : Colors.lightTurq}
                        onClick={toggleChatwoot}
                    />
                </Grid>
            </Grid>
            <Grid container>
                <Text>
                    {provider.attributes.some((x) => x.name === 'Daily Pay Experiment')
                        ? '💸 In daily Pay Experiment'
                        : ''}
                </Text>
            </Grid>
        </Grid>
    );
}

const styles = {
    removeMargin: { marginLeft: 0 },
    iconGroupWrap: { display: 'flex', marginTop: 5, marginRight: 16 },
    providerNameWrap: { display: 'flex', marginTop: 5 },
    adjustIconMargin: { marginLeft: 8 },
    topRowWrap: { display: 'flex', alignItems: 'center', marginBottom: 5, justifyContent: 'space-between' },
};

const HeaderDiv = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    gap: 20,
});
