import React from 'react';
import theme from 'theme';
import { Colors, LoadingSpinner, Text } from 'library';
import { Grid, styled, useMediaQuery } from '@mui/material';
import { useClient } from 'shared';
import { LocationsDropDown } from './components/LocationsDropDown';
import { TimesheetsTable } from './components/TimesheetsTable';

import type { PagingResult, IBusinessLocation } from 'models';
import type { TimesheetWorker, UnclockedInTimesheetWorker } from 'parent-portal/home/new/types';
import { AddRetroHoursModal } from './components/AddRetroHoursModal';
import { Link } from 'react-router-dom';

export function Timesheets() {
    const paramLocation = new URLSearchParams(window.location.search).get('location');
    const paramPage = new URLSearchParams(window.location.search).get('page');
    const paramUnassociatedPage = new URLSearchParams(window.location.search).get('unassociatedPage');
    const page = paramPage ? Number(paramPage) : 1;
    const unassociatedPage = paramUnassociatedPage ? Number(paramUnassociatedPage) : 1;

    const small = useMediaQuery(theme.breakpoints.down('sm'));
    const {
        loading: timesheetsLoading,
        data: timesheets,
        error: timesheetsError,
        refresh,
    } = useClient<PagingResult<TimesheetWorker>>({
        url: `api/hour-report/timesheets/?location=${paramLocation}&page=${page}`,
        options: { sendImmediately: !!paramLocation },
    });

    const {
        loading: shiftsNeedingClockedInLoading,
        data: shiftsNeedingClockedIn,
        error: shiftsNeedingClockedInError,
        refresh: refreshShiftsNeedingClockedIn,
    } = useClient<PagingResult<UnclockedInTimesheetWorker>>({
        url: `api/hour-report/shifts-to-clockin/?location=${paramLocation}&page=${unassociatedPage}`,
        options: { sendImmediately: !!paramLocation },
    });

    const {
        loading: locationsLoading,
        data: locationsData,
        error: locationsError,
    } = useClient<PagingResult<IBusinessLocation>>({
        url: 'api/business-location/?page_size=100',
    });

    const locationObject = paramLocation
        ? locationsData?.results.find((location) => location.id === Number(paramLocation))
        : null;
    const locationName = locationObject?.name;

    function setPage(newPage: number) {
        const params = new URLSearchParams(window.location.search);
        params.set('page', newPage.toString());
        const url = `timesheets?${params.toString()}`;
        window.location.href = url;
    }

    function setUnassociatedPage(newPage: number) {
        const params = new URLSearchParams(window.location.search);
        params.set('unassociatedPage', newPage.toString());
        const url = `timesheets?${params.toString()}`;
        window.location.href = url;
    }

    return (
        <Page>
            <Text variant="h1">Timesheets</Text>
            {!(locationsError || timesheetsError || shiftsNeedingClockedInError) &&
                (locationsLoading ? (
                    <LoadingSpinner loadingStyle={{ color: Colors.darkNavy }} />
                ) : (
                    <>
                        <Grid container direction="row" justifyContent="space-between">
                            <div>
                                <LocationsDropDown data={locationsData} small={small} />
                                {locationObject ? (
                                    <StyledLink to={`/clock-in/?loc_id=${locationObject.address.id}`}>
                                        QR Code
                                    </StyledLink>
                                ) : null}
                            </div>
                            <AddRetroHoursModal businessLocations={locationsData} small={small} onComplete={refresh} />
                        </Grid>
                        {paramLocation && (
                            <TimesheetsContainer>
                                <Text variant="body1" textStyle={{ paddingTop: 20, paddingBottom: 10 }} bold>
                                    {locationName}
                                </Text>
                                {!!shiftsNeedingClockedIn?.results?.length && (
                                    <>
                                        <SpacingContainer>
                                            <Text variant="body2" textStyle={{ paddingBottom: 10 }}>
                                                These workers have yet to clock in
                                            </Text>
                                            <TimesheetsTable
                                                data={shiftsNeedingClockedIn}
                                                small={small}
                                                loading={shiftsNeedingClockedInLoading}
                                                refresh={() => {
                                                    refreshShiftsNeedingClockedIn();
                                                    refresh();
                                                }}
                                                page={unassociatedPage}
                                                setPage={setUnassociatedPage}
                                            />
                                        </SpacingContainer>
                                        <Spacer />
                                    </>
                                )}
                                <SpacingContainer>
                                    {!!timesheets?.results?.length && (
                                        <Text variant="body2" textStyle={{ paddingBottom: 10 }}>
                                            These are the timesheets for the past week of shifts, make sure to action
                                            all of the items that need review.
                                        </Text>
                                    )}
                                    <TimesheetsTable
                                        data={timesheets}
                                        small={small}
                                        loading={timesheetsLoading}
                                        refresh={refresh}
                                        page={page}
                                        setPage={setPage}
                                    />
                                </SpacingContainer>
                            </TimesheetsContainer>
                        )}
                    </>
                ))}
            {(locationsError || timesheetsError) && (
                <Text variant="body1" color={Colors.error} textStyle={{ paddingTop: 20 }}>
                    Error loading data
                </Text>
            )}
        </Page>
    );
}

const Page = styled('div')({
    padding: '20px',
    paddingBottom: 100,
});

const TimesheetsContainer = styled('div')({
    paddingLeft: 0,
});

const SpacingContainer = styled('div')({
    paddingBottom: 20,
});

const Spacer = styled('div')({
    height: 2,
    width: '100%',
    backgroundColor: Colors.lightGrey,
    marginBottom: 30,
    marginTop: 10,
});

const StyledLink = styled(Link)({
    color: Colors.darkNavy,
    textDecorationLine: 'underline',
});
