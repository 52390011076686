import { LeanUser, PagingResult } from 'models';
import { client, queryStringifyObject } from 'shared';

interface ChatwootContactInbox {
    id: number;
    name: string;
    chatwoot_id: number;
    source_id: string;
    enabled: boolean;
}

export interface ChatwootContact {
    id: number;
    user: LeanUser;
    chatwoot_contact_id: number;
    inboxes: ChatwootContactInbox[];
    enabled: boolean;
}

export interface ChatwootInbox {
    id: number;
    name: string;
    chatwoot_inbox_id: number;
}

export const getChatwootContacts = (
    page: number,
    regions: number[],
    users?: string,
): Promise<PagingResult<ChatwootContact>> => {
    const queryParams = queryStringifyObject({ page, regions, users });
    return client(`support/chatwoot-contact/?${queryParams}`);
};

export async function addChatwootContact(userId: number) {
    return client('support/chatwoot-contact/', {
        method: 'POST',
        body: { user_id: userId },
    });
}

export async function addContactInbox(contactId: number, inboxId: string) {
    return client('support/chatwoot-contact-inbox/', {
        method: 'POST',
        body: { contact_id: contactId, inbox_id: parseInt(inboxId) },
    });
}

export async function toggleContactEnabled(contactId: number, enabled: boolean) {
    return client(`support/chatwoot-contact/${contactId}/`, {
        method: 'PATCH',
        body: { enabled },
    });
}

export async function syncChatwootContact(userId: number) {
    return client(`support/chatwoot-contact/sync/`, {
        body: { user_id: userId },
    });
}

export async function toggleInboxEnabled(contactInboxId: number, enabled: boolean) {
    return client(`support/chatwoot-contact-inbox/${contactInboxId}/`, {
        method: 'PATCH',
        body: { enabled },
    });
}

export async function getChatwootInboxes() {
    return client('support/chatwoot-inbox/');
}

export async function syncChatwootInboxes() {
    return client('support/chatwoot-inbox/sync/', {
        method: 'POST',
    });
}

export async function syncChatwootContacts() {
    return client('support/chatwoot-contact/sync-all/', {
        method: 'POST',
    });
}
