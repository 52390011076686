import { Grid } from '@material-ui/core';
import React from 'react';
import { addressToString } from 'internal/business/maps/distances';
import { Colors, Text } from 'library';
import { Link } from 'react-router-dom';
import { IBusinessLocation } from 'models';
import { IBusinessFaqQuestions } from 'parent-portal/home/Models/DashboardData';
import BusinessLocationUnfilledDetails from './BusinessLocationUnansweredFaq';
import { styled } from '@mui/material';

export default function BusinessLocationCard({
    businessLocation,
    faqQuestions,
}: {
    businessLocation: IBusinessLocation;
    faqQuestions: IBusinessFaqQuestions[];
}) {
    return (
        <Grid
            container
            item
            direction="row"
            style={{
                marginBottom: 10,
                padding: 20,
                backgroundColor: Colors.white,
                borderRadius: 18,
                boxShadow: '0px 3px 6px 0px #00000029',
            }}
            xs={12}
        >
            <Grid item xs={12} md={12}>
                <Grid container justify="space-between">
                    <div>
                        <Text bold>{businessLocation.name}</Text>
                        <Text>{addressToString(businessLocation.address)}</Text>
                    </div>
                    <div>
                        <StyledLink to={`/profile/locations/${businessLocation.id}`}>Edit</StyledLink>
                        <StyledLink to={`/clock-in/?loc_id=${businessLocation.address.id}`}>QR Code</StyledLink>
                    </div>
                </Grid>
                {businessLocation.business_contacts.length ? (
                    <Text variant="caption">
                        <Text bold inline variant="caption">
                            Additional Contacts:
                        </Text>{' '}
                        {businessLocation.business_contacts
                            .map((c) => `${c.user.first_name} ${c.user.last_name[0]}`)
                            .join(', ')}
                    </Text>
                ) : null}
                <BusinessLocationUnfilledDetails faqQuestions={faqQuestions} businessLocation={businessLocation} />
            </Grid>
        </Grid>
    );
}

const StyledLink = styled(Link)({
    color: Colors.darkNavy,
    textDecorationLine: 'underline',
    fontSize: 16,
    display: 'block',
    marginBottom: 5,
});
