import { DayOfWeekSelections } from 'parent-portal/forms/JobRequestTypes';
import React from 'react';
import FormField from '../../FormField';
import { FormFieldProps } from '../../FormTypes';
import { Grid } from '@material-ui/core';
import { useFieldValidation } from '../../FormHook';
import { checkIfAnyDayIsSelected } from './JobTimeValidation';
import DayButton from './DayButton';
interface JobDayProps {
    daysOfTheWeek: FormFieldProps<DayOfWeekSelections>;
    disabled?: boolean;
}

export default function JobDayPicker({ daysOfTheWeek, disabled }: JobDayProps) {
    const { value, setValue } = daysOfTheWeek;

    useFieldValidation(daysOfTheWeek, checkIfAnyDayIsSelected(value));

    function setSelected(day: string) {
        if (disabled) return;
        const _day = day as keyof typeof value;

        const updatedDay = {
            ...value[_day],
            selected: !value[_day].selected,
        };

        setValue({ ...value, [_day]: updatedDay });
    }

    return (
        <FormField>
            <Grid container style={{ flexWrap: 'wrap' }}>
                {Object.entries(daysOfTheWeek.value).map(([dayName, day], index) => {
                    return (
                        <DayButton
                            key={index}
                            dayName={dayName}
                            day={day}
                            onClick={() => setSelected(dayName)}
                            style={{ marginRight: 20, marginTop: 10 }}
                        />
                    );
                })}
            </Grid>
        </FormField>
    );
}
